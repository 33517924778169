import React from "react";
import styled from "styled-components/native";
import { appConfig } from "../../config/config";

const StyledTouchableOpacity = styled.TouchableOpacity<
    Pick<AppButtonProps, "colour">
>`
  padding-horizontal: 10px;
  padding-vertical: 10px;
  border-radius: 15px;
  background-color: ${(props) => props.colour};
  width: 100%;
  height: '38px';
`;

const StyledText = styled.Text`
  color: #ffffff;
  text-align: center;
  font-weight: 500;
`;

const disabledColour = "#d6d6d6"

export type AppButtonProps = {
    label: string;
    colour?: string;
    disabled?: boolean;
    onPress: () => any;
    startIcon?: any;
    style?: any;
};

export default function RoundedButtons({
    label,
    colour = appConfig.primaryColour,
    disabled = false,
    startIcon = undefined,
    ...props
}: AppButtonProps): React.ReactElement {
    return (
        <StyledTouchableOpacity
            disabled={disabled}
            activeOpacity={0.8}
            colour={disabled ? disabledColour : colour}
            {...props}
        >
            {startIcon ?
                startIcon
                : null}
            <StyledText>{label}</StyledText>
        </StyledTouchableOpacity>
    );
}
